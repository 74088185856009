















































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CallToAction extends Vue {
  public showShareForm: boolean = false;
  public sharingIconClass: string = "";
  socialShare(platform: string) {
    this.showShareForm = true;
    this.sharingIconClass = platform;
  }
}
