














import { Component, Vue } from "vue-property-decorator";
import Header from "@/common-app/components/Header.vue";
import Footer from "@/common-app/components/Footer.vue";
import ReminderBar from "./home/ReminderBar.vue";
import TotalStats from "./home/TotalStats.vue";
import Cover from "./home/Cover.vue";
import CallToAction from "./home/CallToAction.vue";
import DonorList from "./home/DonorList.vue";
import Campaign from "./home/Campaign.vue";
import DonorProfile from "./home/DonorProfile.vue";

@Component({
  components: {
    Header,
    ReminderBar,
    TotalStats,
    Cover,
    CallToAction,
    DonorList,
    Campaign,
    DonorProfile,
    Footer
  }
})
export default class Home extends Vue {}
