





































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ReminderBar extends Vue {
  public requestConfirmed: boolean = false;
  public donationCompleted: boolean = false;
  confirmRequest() {
    this.requestConfirmed = true;
  }
  completeDonation() {
    this.donationCompleted = true;
  }
}
